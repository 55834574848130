// import dependencies
import React from "react";
import {graphql, Link} from "gatsby";
import Image from "gatsby-plugin-sanity-image";

// import components
import BigCard from "../components/big-card";
import Button from "../components/button";
import CardEngi from "../components/cards/card-engi";
import CardMath from "../components/cards/card-math";
import CardSci from "../components/cards/card-sci";
import CardTech from "../components/cards/card-tech";
import Header from "../components/header";
import Kits from "../components/kits";
import Layout from "../components/layout";
import Resource from "../components/resource";
import Section from "../components/section"
import SectionHeader from "../components/section-header";
import SeparatorBottomTeal from "../components/separators/separator-bottom-teal";
import SeparatorBottomYellow from "../components/separators/separator-bottom-yellow";
import SeparatorTopGrey from "../components/separators/separator-top-grey";
import SeparatorTopTeal from "../components/separators/separator-top-teal";
import SeparatorTopYellow from "../components/separators/separator-top-yellow";

// import styles and assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import photoHead from "../images/logo-big-ingeniosamente.png";
// import photoHeadMob from "../images/index-page/index-blob-mobile.png";
import photoEmu from "../images/emulador-page/emulador.png";
import * as styles from "./styles/home.module.scss";

export default function HomePage ({data}) {
    return (
<Layout>
    <Section color="white">
        <Header bkgImage={photoHead} mobileBkgImage={photoHead} type="logo">
            {/* <h1>Ingeniosamente: Ciencia y tecnología para todas</h1> */}
        </Header>
    </Section>
    <SeparatorTopYellow></SeparatorTopYellow>
    <Section color="yellow">
        <div className={`container ${styles.intro}`}>
            <div className="row">
                <div className={`col-sm-12 ${styles.introGridBox}`}>
                    <div className="imgHolder"></div>
                    <div className={styles.introBox}>
                        <h1>¿Qué es ingeniosamente?</h1>
                        <h4>Ingeniosamente es una plataforma que cuenta con actividades, talleres y entretenidas notas en ciencia y tecnología con enfoque de género, para que explores y te aproximes a las STEM.</h4>
                        <h4>Queremos que tú seas la próxima líder en ciencia y tecnología, por eso te invitamos a inspirarte con Ingeniosamente.</h4>
                    </div>
                </div>
            </div>
        </div>
    </Section>
    <SeparatorBottomYellow></SeparatorBottomYellow>
    <Section color="white">
        <div className={`${styles.emuSection}`}>
            <BigCard color="white">
                <div className="col-sm-12 order-sm-first col-md-6 order-md-last align-self-center">
                    <img src={photoEmu} alt="emulador"/>
                </div>
                <div className="d-flex flex-column col-sm-12 col-md-6 align-self-center">
                    <h1>Emulador STEM Ingeniosamente</h1>
                    <h4>¿Te imaginas un laboratorio virtual donde puedas explorar con elementos de ciencia y tecnología a la vez? Descubre aquí las STEM de manera remota y digital, emulando el espacio real de trabajo en ciencia y tecnología, con herramientas simuladas.</h4>
                    <Button url={"/ninas-emulador"} color="turquoise" buttonText={"Ingresa al emulador"}></Button>
                </div>
            </BigCard>
        </div>
    </Section>
    <SeparatorTopTeal />
    <Section color={"turquoise"}>
        <div className={`container ${styles.workMarket}`}>
            <SectionHeader color="white">
                <h1>Conoce sobre el mercado laboral en STEM</h1>
                <h4>Si eres apoderada o apoderado, te invitamos a descubrir las posibilidades que tu hija o niña a cargo podría tener en un futuro laboral en ciencia y tecnología. Descubre con experiencias reales las posibilidades en estos rubros.</h4>
            </SectionHeader>
            <div className={`row ${styles.workMarketBox}`}>
                {data.merlab.edges.map((edge) => {
                    switch (edge.node.tags[0]) {
                        case "mathematics":
                            return(
                                <div key={edge.node.id} className="col-sm-12 col-md-6">
                                    <CardMath desc={edge.node.title} postUrl={`/mercado-laboral/${edge.node.slug.current}`}/>
                                </div>
                            );
                        case "engineering":
                            return(
                                <div key={edge.node.id} className="col-sm-12 col-md-6">
                                    <CardEngi desc={edge.node.title} postUrl={`/mercado-laboral/${edge.node.slug.current}`}/>
                                </div>
                            );
                        case "technology":
                            return(
                                <div key={edge.node.id} className="col-sm-12 col-md-6">
                                    <CardTech desc={edge.node.title} postUrl={`/mercado-laboral/${edge.node.slug.current}`}/>
                                </div>
                            );
                        case "science":
                            return(
                                <div key={edge.node.id} className="col-sm-12 col-md-6">
                                    <CardSci desc={edge.node.title} postUrl={`/mercado-laboral/${edge.node.slug.current}`}/>
                                </div>
                            );
                        default:
                            return(
                                <div key={edge.node.id} className="col-sm-12 col-md-6">
                                    <CardMath desc={edge.node.title} postUrl={`/mercado-laboral/${edge.node.slug.current}`}/>
                                </div>
                            );
                    }
                })}
            </div>
            <div className="row">
                <div className="d-flex col-12 justify-content-center">
                    <Button url={"/mercado-laboral"} color="orange" buttonText={"Conoce más"}></Button>
                </div>
            </div>            
        </div>
    </Section>
    <Section color="grey">
    <SeparatorBottomTeal />
        <div className={`container ${styles.classRes}`}>
            <SectionHeader color="turquoise">
                <h1>Recursos para el aula docente</h1>
                <h4>Material STEM disponible para profesoras y profesores, con el objetivo de inspirar en ciencia y tecnología a alumnas, niñas y adolecentes.</h4>
            </SectionHeader>
            <div className={`row ${styles.classResBox}`}>
                {data.classRes.edges.map((edge) => (
                    <div key={edge.node.id} className="col-sm-12 col-md-6">
                        <Resource>
                            <div className="photo" key={edge.node.mainImage.asset.id}>
                                <Image 
                                    {...edge.node.mainImage}
                                    width={320}
                                    height={320}
                                    alt="Imágen de recursos para el aula" />
                            </div>
                            <div className="box">
                                <h4>{edge.node.title}</h4>
                                <div className="separator"></div>
                                <p>{edge.node.excerpt}</p>
                                <Link to={`/recursos-aula/${edge.node.slug.current}`}><FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
                            </div>
                        </Resource>
                    </div>
                ))}
            </div>
        </div>
    </Section>
    <Section color="white">
        <div className={`container section-spacing ${styles.kits}`}>
            <SectionHeader color="turquoise">
                <h1>Cajas interactivas</h1>
                <h4>Kit de Herramientas físicas que te permitirán realizar actividades y experimentos en ciencia y tecnología.</h4>
            </SectionHeader>
            <Kits/>
        </div>
    <SeparatorTopGrey />
    </Section>
</Layout>
    );
}

export const query = graphql`
query IndexPageQuery {
    merlab: allSanityMercadorLaboralPost(
    limit: 4, 
    sort: {order: DESC, fields: publishedAt}) {
        edges {
            node {
                id
                title
                tags
                slug {
                   current
                }
                excerpt
            }
        }
    }
    classRes: allSanityPost (
    limit: 2, 
    filter: {category: {title: {eq: "Recursos Ingeniosas"}}}, 
    sort: {order: DESC, fields: publishedAt}) {
        edges {
            node {
                id
                title
                slug {
                    current
                }
                excerpt
                mainImage {
                    ...ImageWithPreview
                }
            }
        }
    }
}
`;