// extracted by mini-css-extract-plugin
export const sectionSpacing = "home-module--section-spacing--3bOE0";
export const rowSpacing = "home-module--row-spacing--20bnU";
export const rowSpacingSml = "home-module--row-spacing-sml--377km";
export const intro = "home-module--intro--3Aw-k";
export const introGridBox = "home-module--introGridBox--17I1S";
export const introBox = "home-module--introBox--1wR-b";
export const emuSection = "home-module--emuSection--1siCi";
export const workMarket = "home-module--workMarket--3Xx3d";
export const workMarketBox = "home-module--workMarketBox--3_nAT";
export const classRes = "home-module--classRes--182QO";
export const classResBox = "home-module--classResBox--1hkbE";
export const kits = "home-module--kits--3S_z0";